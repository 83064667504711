import { Injectable, APP_INITIALIZER } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { of ,  Observable } from 'rxjs';

@Injectable()
export class LookupService {
    private lookupData: any;
    constructor(
        private http: HttpClient) { }

    load() {
        return this.http.get('assets/lookup.json')
            .toPromise()
            .then(response => this.lookupData = response)
            .catch(error => this.handleError<any>(`load`));
    }

    getLookup(key: string): any {
        return this.lookupData[key];
    }

    get(key: any) {
        return this.lookupData[key];
    }
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
export function LookupFactory(config: LookupService) {
    return () => config.load();
}
export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: LookupFactory,
        deps: [LookupService],
        multi: true
    };
}
const LookupModule = {
    init: init
};

export { LookupModule };
