import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { Global } from '../Shared/global';
import { MessageService } from '.';

@Injectable()
export class LocationService {
    constructor(private http: HttpClient,
        public global: Global,
        private messageService: MessageService,
        private configService: ConfigService) { }

    getRootUrl(): string {
        return this.configService.getApi('publicApiRootUrl');
    }

    getNurseryUrl(): string {
        return this.configService.getApi('nurseryUrl');
    }
    getCertificateListByLocationYearId(locationYearId: string): any {
        const url = `${this.getRootUrl()}CertificateListByLocationYearId?LocationYearId=${locationYearId}`;
        return this.http.get(url).pipe(
            tap(_ => this.log(`api result is success`)),
            catchError(this.handleError(`api result status is unsuccessfull.`))
        );
    }

    locationList(): any {
        const url = `${this.getRootUrl()}LocationList`;
        return this.http.get(url).pipe(
            tap(_ => this.log(`api result is success`)),
            catchError(this.handleError(`api result status is unsuccessfull.`))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    private log(message: string) {
        this.messageService.add(`AppService: ${message}`);
    }
}
