import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatMaterialModule } from './Shared/material/material-module';
import { ConfigService, ConfigModule } from './providers/config.service';
import { MessageService, LookupService, LookupModule, LocationService } from './providers';
import { Global } from './Shared/global';
import { HeaderComponent, FooterComponent } from './Shared';
import { PublicModule } from './public/public.module';


@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatMaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TextMaskModule,
    RouterModule.forRoot(APP_ROUTES, {
      useHash: true,
      enableTracing: false
    }),
    PublicModule
  ],

  providers: [
    ConfigService,
    ConfigModule.init(),
    LookupService,
    LookupModule.init(),
    Global,
    MessageService,
    LocationService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
