import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  navIsFixed: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) { }
  
  @HostListener('window:scroll', [])
  
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && window.pageYOffset || document.documentElement.scrollTop
      || document.body.scrollTop < 10) { this.navIsFixed = false; }
  }

}
