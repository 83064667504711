import { Component, Input } from '@angular/core';

@Component({
  selector: 'nila-error-message',
  template: `<mat-card *ngIf="errorMessage?.length" fxLayoutAlign="space-around center" class="error-card">
              <div *ngFor="let err of errorMessage">
              <ul type="disc">
                  <li>{{err}}</li>
              </ul>
          </div>
        </mat-card>`,
  })
export class ErrorMessageComponent {

  @Input() errorMessage: any[];
}
