<div fxLayout="column" [ngClass]="{'show-scroll': navIsFixed}">
    <nilaheader></nilaheader>
    <div>
        <div fxFlex="auto"></div>
        <div fxFlex="nogrow" class="container margin" [ngStyle.gt-sm]="{'min-height.px': 600}" [ngStyle.lt-sm]="{'min-height.px': 300}">
            <router-outlet></router-outlet>
        </div>
        <div fxFlex="auto"></div>
    </div>
    <nilafooter></nilafooter>
</div>