import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LocationService, LookupService } from '../../providers';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Global } from '../../Shared/global';

@Component({
  selector: 'nila-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  locationYearList: any[];
  errorMessages: any[];
  displayedColumns = ['BusinessLocationName',
    'SellingModeText',
    'PrimaryActivityTextShort',
    'CountyText',
    'Certificates'];
  certificates: any[] = [];
  primaryActivity: any;
  counties: any;
  nurseryUrl: string;
  search: any = {
    CountyCode: '',
    PrimaryActivityCode: ''
  };

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private locationService: LocationService, public global: Global, private lookupService: LookupService) {
    this.nurseryUrl = this.locationService.getNurseryUrl();
    this.primaryActivity = this.lookupService.getLookup('primaryActivity');
    this.counties = this.lookupService.getLookup('counties');
    if (this.global.isMobile()) {
      this.displayedColumns = ['BusinessLocationName'];
    }
  }

  ngOnInit() {
    this.getLocationList();
  }

  onPageChange(ev) {
    window.scrollTo(0, 0);
    console.log('testing page change');
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getLocationList(): void {
    this.locationYearList = [];
    this.errorMessages = [];
    this.locationService.locationList().subscribe(response => {
      const results = response;
      if (results) {
        if (results.Success && results.Data.length > 0) {
          this.locationYearList = results.Data;
        } else {
          this.errorMessages = results.MessageList;
        }
      }
      this.dataSource.data = this.locationYearList;
    },
      error => this.errorMessages = <any>error);
  }
  getCertificateListByLocationYearId(locationYearId: string) {
    this.certificates = [];
    this.locationService.getCertificateListByLocationYearId(locationYearId).subscribe(response => {
      const results = response;
      if (results) {
        if (results.Success && results.Data.length > 0) {
          this.certificates = results.Data;
        } else {
          this.errorMessages = results.MessageList;
        }
      }
    },
      error => this.errorMessages = <any>error);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    filterValue = filterValue.replace(/[“”‘’]/g, "'");
    this.dataSource.filter = filterValue;
  }
  onSearchChange() {
    const data = (this.search.CountyCode && !this.search.PrimaryActivityCode)
      ? this.locationYearList.filter(u => u.CountyCode == this.search.CountyCode)
      : ((this.search.PrimaryActivityCode && !this.search.CountyCode) ?
        this.locationYearList.filter(u => u.PrimaryActivityCode == this.search.PrimaryActivityCode)
        : ((this.search.PrimaryActivityCode && this.search.CountyCode) ?
          this.locationYearList.filter(u => u.PrimaryActivityCode == this.search.PrimaryActivityCode
            && u.CountyCode == this.search.CountyCode)
          : this.locationYearList));

    this.dataSource.data = data;
    if (this.search.SearchText) { this.applyFilter(this.search.SearchText); }
  }
}
