import { Component, OnInit } from '@angular/core';
import { Global } from '../global';
import { ConfigService } from '../../providers/config.service';

@Component({
    selector: 'nilaheader',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    errorMessage: any[];
    isAgencyMenuPanelOpen: boolean = false;
    constructor(public global: Global, private configService: ConfigService, ) { }

    getRedirectUrl(): string {
        return this.configService.getApi('redirectUrl');
    }
    gotoHelp() {
        window.open('/help', '_blank', 'noopener');
    }
}
