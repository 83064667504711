<mat-toolbar class="nila-footer-toolbar">
  <div fxFlex="auto"></div>
  <div fxFlex="nogrow" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="left center" class="container agency-footer-link">
    <a href="https://agriculture.delaware.gov/plant-industries/">
      <img src="/assets/images/ag-logo-white-300px.png" alt="Image of the Delaware Department of Agriculture logo" class="agency-footer_logo center-block">
    </a>
    <div fxLayout="row" fxLayout.lt-sm="column">
      <a *ngFor="let link of footerLinks" href="{{link.path}}" target="_blank" rel="noopener noreferrer">
        {{link.label}}
      </a>
    </div>
  </div>
  <div fxFlex="auto"></div>
</mat-toolbar>