<mat-grid-list cols="2" rowHeight="500px">
  <div *ngFor="let video of videos">
    <mat-grid-tile>
      <div class="video-container">
        <h3>{{video.title}}</h3>
        <video width="450" height="300" [poster]="video.poster" crossorigin="anonymous" controls [title]="video.title" #videoPly>
          <source [src]="video.videoUrl" type="video/mp4" #videoSrc />
          <track kind="captions" label="English captions" [src]="video.captionsUrl" srclang="en" default #videoTrack />
        </video>
        <div class="video-description">
          <p> {{video.description}}</p>
        </div>
      </div>
    </mat-grid-tile>
  </div>
</mat-grid-list>