import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nilafooter',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }
  footerLinks: any[] = [
    {
        path: 'https://agriculture.delaware.gov/plant-industries/',
        label: 'Plant Industries'
    },
    {
        path: 'https://agriculture.delaware.gov/contact-us/',
        label: 'Contact Us'
    },
    {
        path: 'https://agriculture.delaware.gov/news/',
        label: 'newsroom'
    },
    {
        path: 'https://agriculture.delaware.gov/foia/',
        label: 'FOIA Request'
    },
];
  ngOnInit() {
  }

}
