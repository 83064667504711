<div fxLayout="column">
  <div fxFlex="40">
    <h3 class="dda_welcometext">WELCOME</h3>
    <div class="margin-bottom">Welcome to the Delaware Department of Agriculture- Nursery Industry License Application Website. The Nursery and Nursery
      Stock Law requires that any place where nursery stock is produced or any person who acquires plant material to resell
      or distribute, must hold a valid Nursery Industry License for each sales location prior to initiating business operations.
    </div>
    <div class="margin-bottom">This website will allow you to apply and renew your Nursery Industry License, as well as receive and print your license.
      In addition your Certificate of Inspection, Inspection Reports, and Specialty Certificates will all be available online.
    </div>
    <div class="margin-bottom">Log in or create a new account here
      <a href="{{nurseryUrl}}" target="_blank">{{nurseryUrl}}</a>
    </div>
  </div>
  <h3 class="dda_welcometext">NURSERIES</h3>
  <div class="search-input-margin margin-top margin-bottom" fxLayout="row" fxLayout.xs="column">
    
    <div fxLayout="row">
      <mat-icon class="margin-top" aria-label="search icon" color="primary">search</mat-icon>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" (paste)="applyFilter($event.target.value)" placeholder="Filter"
          [(ngModel)]="search.SearchText">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select class="simplified font-size-16" aria-label="status" placeholder="Activity" [(value)]="search.PrimaryActivityCode"
          [(ngModel)]="search.PrimaryActivityCode" (selectionChange)="onSearchChange()">
          <mat-option *ngFor="let item of primaryActivity" [value]="item.PrimaryActivityCode">
            {{item.PrimaryActivityText}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select class="simplified font-size-16" aria-label="counties" placeholder="County" [(value)]="search.CountyCode" [(ngModel)]="search.CountyCode"
          (selectionChange)="onSearchChange()">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let item of counties" [value]="item.CountyCode">
            {{item.CountyText}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex>
    <div>
      <div fxLayout="column" class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort matSortActive="BusinessLocationName" matSortDirection="asc" matSortDisableClear>
          <ng-container matColumnDef="BusinessLocationName">
            <mat-header-cell fxFlex *matHeaderCellDef mat-sort-header>Business Name</mat-header-cell>
            <mat-cell fxFlex *matCellDef="let element" fxLayout="column">
              <div class="margin-bottom">
                <a *ngIf="element.BusinessWebsiteUrl" color="primary" href="{{element.BusinessWebsiteUrl}}" target="_blank">{{element.BusinessName}} </a>
                <div *ngIf="!element.BusinessWebsiteUrl">{{element.BusinessName}}</div>
              </div>
              <div>{{element.LocationName}}</div>
              <div>{{element.StreetAddress}}</div>
              <div>{{element.City}} {{element.USStateCode}} {{element.ZipCode}}</div>
              <div *ngIf="global.isMobile()" class="action-links">
                <div>Business Type: {{element.SellingModeText}}</div>
                <div>Activity: {{element.PrimaryActivityTextShort}}</div>
                <div>County: {{element.CountyText}}</div>
                <div>
                  <a color="primary" [matMenuTriggerFor]="plantMaterial" (click)="getCertificateListByLocationYearId(element.LocationYearId)">View Certificates</a>
                  <mat-menu #plantMaterial="matMenu">
                    <div *ngFor="let cert of certificates; last as last" class="margin">
                      <div>Type: {{cert.CertificateTypeText}}</div>
                      <div>ID: {{cert.CertId}} </div>
                      <div>Effective from {{cert.EffectiveDate | date:'yyyy-MMM-dd'}}</div>
                      <div>Expires on {{cert.ExpirationDate | date:'yyyy-MMM-dd'}}</div>
                      <hr *ngIf="!last" />
                    </div>
                  </mat-menu>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="SellingModeText">
            <mat-header-cell fxFlex="15" *matHeaderCellDef mat-sort-header>Business Type</mat-header-cell>
            <mat-cell fxFlex="15" *matCellDef="let element">
              {{element.SellingModeText}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PrimaryActivityTextShort">
            <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header>Primary Activity</mat-header-cell>
            <mat-cell fxFlex="20" *matCellDef="let element">
              {{element.PrimaryActivityTextShort}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CountyText">
            <mat-header-cell fxFlex="10" *matHeaderCellDef mat-sort-header>County</mat-header-cell>
            <mat-cell fxFlex="10" *matCellDef="let element">
              {{element.CountyText}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Certificates">
            <mat-header-cell fxFlex="15" *matHeaderCellDef> Certificates </mat-header-cell>
            <mat-cell fxFlex="15" class="action-links" *matCellDef="let element">
              <div>
                <a color="primary" [matMenuTriggerFor]="plantMaterial" (click)="getCertificateListByLocationYearId(element.LocationYearId)">View</a>
                <mat-menu #plantMaterial="matMenu">
                  <div *ngFor="let cert of certificates; last as last" class="margin">
                    <div>Type: {{cert.CertificateTypeText}}</div>
                    <div>ID: {{cert.CertId}} </div>
                    <div>Effective: {{cert.EffectiveDate | date:'yyyy-MMM-dd'}}</div>
                    <div>Expires: {{cert.ExpirationDate | date:'yyyy-MMM-dd'}}</div>
                    <hr *ngIf="!last" />
                  </div>
                </mat-menu>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSize]="global.pageSize" (page)="onPageChange($event)" [pageSizeOptions]="global.pageSizeOptions"></mat-paginator>
      </div>
    </div>
  </div>
</div>