import { Component } from '@angular/core';
import { Global } from '../../Shared/global';
import { LookupService } from '../../providers';

@Component({
  selector: 'nila-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  videos: any[] = [];
  constructor(public global: Global, private lookupService: LookupService) {
    this.videos = this.lookupService.getLookup('videosInformation');
  }
}
