import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMaterialModule } from './material/material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';

import {
    ErrorMessageComponent
} from '.';

export const SHARED_COMPONENTS = [
    ErrorMessageComponent,
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatMaterialModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TextMaskModule
    ],
    declarations: [
        ...SHARED_COMPONENTS,
    ],
    exports: [
        ...SHARED_COMPONENTS
    ],

})
export class SharedModule { }
