<mat-toolbar class="header-toolbar">
  <div fxFlex="auto"></div>
  <div fxFlex="nogrow" fxLayout="row" class="container clf-header">
    <div class="navbar-header">
      <a href="https://delaware.gov">
        <img src="/assets/images/delaware_global_d@2x.png" alt="The Delaware.gov logo" class="border_none delaware_d_logo">
      </a>
      <a href="https://delaware.gov">
        <img src="/assets/images/delaware_global_text@2x.png" alt="Delaware.gov - The Offical Website of the First State" class="border_none delaware_text">
      </a>
    </div>
    <div fxHide.lt-sm fxShow class="masthead-nav" fxLayout="row">
      <a target="_blank" href="http://delaware.gov/topics/agencylist_alpha">
        <i class="fa fa-building fa-lg" aria-hidden="true"></i> Agencies</a>
      <a target="_blank" href="http://news.delaware.gov">
        <i class="fa fa-newspaper-o fa-lg"></i> News</a>
      <a target="_blank" href="http://delaware.gov/topics">
        <i class="fa fa-comments-o fa-lg"></i> Topics</a>
      <a target="_blank" href="http://delaware.gov/help/degov-contact.shtml">
        <i class="fa fa-mobile fa-fw fa-lg"></i> Contact</a>
    </div>
  </div>
  <div fxFlex="auto"></div>
</mat-toolbar>

<mat-toolbar class="menubar">
  <div fxFlex="auto"></div>
  <div fxFlex="nogrow" fxLayoutAlign="space-around center" fxLayout="row" class="container nila-header-align">
    <div fxFlex class="nila-header" [ngStyle.lt-sm]="{'font-size.px': 20}">Nursery Industry License Application</div>
    <!-- <mat-icon aria-label="help" color="primary" routerLink="/help" routerLinkActive="target" matTooltip="Help">help</mat-icon> -->
    <a class="help-link" routerLink="/help" target="_blank">
      <mat-icon aria-label="help" color="primary" matTooltip="Help">help</mat-icon>
    </a>
  </div>
  <div fxFlex="auto"></div>
</mat-toolbar>