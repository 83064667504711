import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { DOCUMENT } from '@angular/platform-browser';

@Injectable()
export class Global {
    role: string;
    email: string;
    nilaNurserySessionId: string;
    SeesionDetails: any = {};
    userGuid: string;
    errorMessage: any[] = [];
    phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    radix = 10;
    pageSize: number = 10;
    pageEvent: PageEvent;
    pageSizeOptions = [5, 10, 25, 50, 100];
    decimalNumberMask = createNumberMask({
        prefix: '',
        suffix: '',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 3
    });
    numberMask = createNumberMask({
        prefix: '',
        suffix: ''
    });
    navigations: any = {};
    hasMenu: boolean = true;
    isNext: boolean = false;
    hasReview: boolean = false;
    constructor(private route: ActivatedRoute, private router: Router,  @Inject(DOCUMENT) private document: Document) {
    }

    getCookie(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    convertToNumber(number: string): any {
        if (number) {
            const Phone = number.match(/\d+/g);
            number = Phone.join('');
        }
        return number;
    }
    setPageSize(size: any) {
        localStorage.setItem('nursery-page-size', size);
    }

    getPageSize(): any {
        return localStorage.getItem('nursery-page-size');
    }
    onPageChange(event) {
        this.scrollToTop();
        this.pageEvent = event;
        this.setPageSize(this.pageEvent.pageSize);
        this.pageSize = this.pageEvent.pageSize;
    }
    isMobile() {
        if (window.innerWidth < 820) {
            return true;
        } else {
            return false;
        }
    }

    public scrollToTop() {
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop ||
                document.body.scrollTop; if (currentScroll > 0) {
                    window.requestAnimationFrame(smoothscroll);
                    window.scrollTo(0, currentScroll - (currentScroll / 5));
                }
        })();
    }
}
