import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';

import { PUBLIC_COMPONENTS, PUBLIC_ROUTES } from './public.route';
import { LocationService } from '../providers/location.services';
import { MatMaterialModule } from '../Shared/material/material-module';
import { SharedModule } from '../Shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatMaterialModule,
        SharedModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TextMaskModule,
        RouterModule.forChild(PUBLIC_ROUTES),
    ],
    declarations: [
        ...PUBLIC_COMPONENTS
    ],
    providers: [LocationService],
})

export class PublicModule { }
